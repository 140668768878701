
import {
  SET_USER,
} from '../actions/actionTypes';


export const defaultValue = {
  user: {}
}

export const userReducer = (state = defaultValue, action) => {
  switch (action.type){
    case SET_USER:
      return { ...state, user: action.user };
    default:
      return state;
  }
}