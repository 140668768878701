
import {
  CLICK_LOGIN_OK,
} from '../actions/actionTypes';


export const defaultValue = {
  menu: []
}

export const menuReducer = (state = defaultValue, action) => {
  switch (action.type){
    case CLICK_LOGIN_OK:
      return { ...state, menu: action.menu };
    default:
      return state;
  }
}