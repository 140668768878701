import orange from '@material-ui/core/colors/deepOrange';
import blueGrey from '@material-ui/core/colors/blueGrey';
import lightBlue from '@material-ui/core/colors/blue';
const {REACT_APP_DRAWER_SIZE} = process.env;

// zenite colors: #0a3358, #42c4da

const drawerWidth = parseInt(REACT_APP_DRAWER_SIZE) - 10;

const fab = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 1109
};

const appBarShift = {
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: drawerWidth,
}

const common = {
  fab: fab,
  appBarShift: appBarShift  
}

export const darkPallet = {
  palette: {
    primary: blueGrey,
    secondary: orange,
    type: 'dark'
  },
  ...common
};

export const lightPallet = {
  palette: {
    primary: {
      main: lightBlue[600]
    },
    secondary: {
      main: '#42c4da'
    },
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f'
    },
    type: 'light'
  },
  ...common
};