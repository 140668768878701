
import {
  CLICK_TOGGLE_TOAST,
} from '../actions/actionTypes';


export const defaultToastValue = {
  toast: {
    show: false,
    message: '',
    type: ''
  }
}

export const toastReducer = (state = defaultToastValue, action) => {
  switch (action.type){
    case CLICK_TOGGLE_TOAST:
      return { ...state, toast: action.toast };
    default:
      return state;
  }
}