
import {themeReducer} from './themeReducer';
import {toastReducer} from './toastReducer';
import {drawerReducer} from './drawerReducer';
import {loadingReducer} from './loadingReducer';
import {userReducer} from './userReducer';
import {menuReducer} from './menuReducer';
import {statsReducer} from './statsReducer';
import {combineReducers} from 'redux';

export const Reducers = combineReducers({
  themeState: themeReducer,
  toastState: toastReducer,
  drawerState: drawerReducer,
  loadingState: loadingReducer,
  menuState: menuReducer,
  userState: userReducer,
  statsState: statsReducer
});