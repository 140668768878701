
import {
  CLICK_TOOGLE_DRAWER,
} from '../actions/actionTypes';


export const defaultValue = {
  open: localStorage.getItem('mn')?(localStorage.getItem('mn') === 'true'):false
}

export const drawerReducer = (state = defaultValue, action) => {
  switch (action.type){
    case CLICK_TOOGLE_DRAWER:
      localStorage.setItem('mn', state.open?false:true);
      return { ...state, open: !state.open };
    default:
      return state;
  }
}