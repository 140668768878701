
import {
  CLICK_TOGGLE_LOADING,
} from '../actions/actionTypes';


export const defaultValue = {
  loading: false
}

export const loadingReducer = (state = defaultValue, action) => {
  switch (action.type){
    case CLICK_TOGGLE_LOADING:
      return { ...state, loading: !state.loading };
    default:
      return state;
  }
}