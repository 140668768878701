
import {
  SET_STATS,
} from '../actions/actionTypes';


export const defaultValue = {
  user: {}
}

export const statsReducer = (state = defaultValue, action) => {
  switch (action.type){
    case SET_STATS:
      return { ...state, stats: action.stats };
    default:
      return state;
  }
}