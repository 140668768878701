import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import ThemeApp from './ThemeApp';
import { Provider } from 'react-redux';
import { Store } from './store';

import Button from '@material-ui/core/Button';

/** Message new version */
class MessageNewVersion extends React.PureComponent{
  
  state = {
    show: false
  }

  _eventReceived = () => {
    this.setState({show: true});
  }

  componentDidMount(){
    document.addEventListener('newversion', this._eventReceived);
  }

  componentWillUnmount(){
    document.removeEventListener('newversion', this._eventReceived);
  }

  render(){
    const {show} = this.state;
    if(!show) return null;
    return (
      <div id="forceupdate">
        Nova versão disponível. 
        <Button onClick={() => window.location.reload(true)}>Atualizar</Button>
      </div>
    );
  }
};

ReactDOM.render(
  <Provider store={Store}>
    <MessageNewVersion />
    <ThemeApp />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (worker) => {
    document.dispatchEvent(new CustomEvent('newversion', {}));
  }
});
