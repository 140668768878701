/**
 * Javascript File 
 * @author: Aderbal Nunes <aderbal@zenitetecnologia.com.br>
 * @since: 05/03/2020
 * 
 * Copyright 2020 Zenite Tecnologia.
 */

import React, { Component } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {connect} from 'react-redux';

const App = React.lazy(() => import('./App'));

class ThemeApp extends Component{

  render(){
    const {theme} = this.props;

    return (
      <React.Suspense fallback={<div>Loading...</div>}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <CssBaseline />
          <App />
        </MuiThemeProvider>
      </React.Suspense>
    );
  };
}

// REDUX ##
const mapStateToProps = store => ({
  theme: store.themeState.theme
});
// ########

export default connect(mapStateToProps)(ThemeApp);