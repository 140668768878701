
import {
  CLICK_CHANGE_THEME,
} from '../actions/actionTypes';

import {lightPallet} from '../theme';

const themeInitialValue = {
  theme: lightPallet
}

export const themeReducer = (state = themeInitialValue, action) => {
  switch (action.type){
    case CLICK_CHANGE_THEME:
      return { ...state, theme: action.theme };
    default:
      return state;
  }
}